import React, { useEffect, useState,useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Spinner from "../../../../includes/spinner/Spinner";
import { BigAdultChild } from "../Buttons";
import { LocalStorage, SessionStorage } from "../../../utils/storage";
import { DEFAULT_LANG, KEYS } from "../../../utils/constant";
import { useLocation, useNavigate } from "react-router";
import { route } from "../../../utils/route";
import { useTranslation } from "react-i18next"
import MaxOccupancyError from "./maxOccupancyError";
import { getMaxCount } from "../../../utils/utils";
import AppContext from "../../../utils/appContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  maxWidth: 460,
  minWidth: 460,
  p: 0,
  bgcolor: "#fff",
  textAlign: "left"
};

const AddRoomModal = ({ openAddRoom, setopenAddRoom, getRoomList, siteConfig }) => {
  let location = useLocation()
  const { t } = useTranslation()
  let navigate = useNavigate()
  const context = useContext(AppContext)
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
  let newroomcount = cartItems.length + 1;

  const [adultcount, setadultcount] = useState(1);
  const [childcount, setchildcount] = useState(0);
  const [diplayMaxOccError, setdisplayMaxOccError] = useState(0);

  const handleCloseAddRoom = () => {
    setopenAddRoom(false);
    setadultcount(1)
    setchildcount(0)
  };

  useEffect(() => {
    if((adultcount + childcount) > siteConfig?.max_occupancy){
      setdisplayMaxOccError(1);
    }else{
      setdisplayMaxOccError(0);
    }
  }, [adultcount, childcount])

  useEffect(() => {
    setadultcount(!openAddRoom ? 1 : adultcount);
    setchildcount(!openAddRoom ? 0 : childcount);
    if (openAddRoom) {
      document.body.classList.add('bodyScrollHidden');
    } else {
      document.body.classList.remove('bodyScrollHidden');
    }
    return () => {
      document.body.classList.remove('bodyScrollHidden');
    };
  }, [openAddRoom])

  const addRooms = () => {
    let childindex = [];
    if(childcount > 0){
      childindex = [...Array(childcount).keys()];
    }
    let newRoom = {"room":newroomcount,"adults":adultcount,"childrens":childindex,"price":[],"room_info":{"room_id":0, "rate_id":0, "room_name": "", "rate_name":""}};
    cartItems.push(newRoom);
    LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, newroomcount)
    SessionStorage.setJSON(KEYS.ROOM_DATA, cartItems);
    context.setCartData(cartItems)
    setopenAddRoom(false)
    window.scrollTo(0, 0)
    if (location.pathname === route.checkout || location.pathname === route.roomDetail) {
      navigate(route.roomList)
    }
    
    getRoomList()
  }
  return (
    <Modal
      open={openAddRoom}
      onClose={handleCloseAddRoom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="addModalPopup"
      role="dialog"
			aria-modal="true"
    >
      <Box sx={style}>
        <div id="addRoomModal" className="white-popup-block">
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {t("add_room")}
            <button aria-label='Close Button' type="button" className="mfp-close" onClick={() => handleCloseAddRoom()}>Close</button>
          </Typography>
          <div className="columnWrap alignItemsCenter marginB15">
            <div className="column30">
              <label for={`adult${newroomcount}`}>{t("adults")}</label>
            </div>
            <div className="column70">
              <BigAdultChild
                defaultValue={adultcount}
                type="adult"
                minValue="1"
                maxValue={siteConfig.max_adult}
                maxOccupancy={siteConfig.max_occupancy}
                roomNumber={newroomcount}
                setcount={setadultcount}
                occupancyCount={adultcount+childcount}
              />
            </div>
          </div>
          <div className="columnWrap alignItemsCenter">
            <div className="column30">
              <label for={`children${newroomcount}`}>{t("children")}{" "}({t('under_16')})</label>
            </div>
            <div className="column70">
              <BigAdultChild
                defaultValue={childcount}
                type="children"
                minValue="0"
                maxValue={siteConfig.max_child}
                maxOccupancy={siteConfig.max_occupancy}
                roomNumber={newroomcount}
                setcount={setchildcount}
                occupancyCount={adultcount+childcount}
              />
            </div>
          </div>
          <MaxOccupancyError diplayMaxOccError={diplayMaxOccError} />
          <div className="modalBottomBtn paddingT30">
            <a
              className={`customBtn customBtnDark w100 ${
                diplayMaxOccError === 1
                  ? "btnDisable"
                  : ""
              }`}
              href="javascript:void(0);"
              onClick={() => diplayMaxOccError === 1 ? false : addRooms()}
              aria-labelby={t("check_availability")}
              disabled={
                diplayMaxOccError === 1
                  ? true
                  : false
              }
            >
              {t("check_availability")}
            </a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddRoomModal;
