import React,{useContext} from "react";
import { DEFAULT_LANG, KEYS } from "../../../../utils/constant";
import { LocalStorage, SessionStorage } from "../../../../utils/storage";
import arrowLeftIcon from "../../../../styles/images/arrow-left.png";
import { useLocation, Link,
  Routes,
  Route,
  useNavigate } from "react-router-dom";
import { route } from "../../../../utils/route";
import { useTranslation } from "react-i18next"
import AppContext from "../../../../utils/appContext";


const BookingSteps = () => {
  const location = useLocation();
  const { t } = useTranslation()
  let context = useContext(AppContext);
  let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
  let currentRoom =LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
  let roomNo = 0;
  const navigate = useNavigate();
  const setRoomStepSelected=context.setRoomStepSelected;
  const roomStepSelected=context.roomStepSelected

  const navigateToRoomInfo= (args)=>{
    if (context.overlay && location.pathname !== route.roomList && location.pathname !== route.roomListIndex) return
    setRoomStepSelected({ ...args })
    if ((location.pathname === route.roomList || location.pathname === route.roomListIndex) && LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM) > 1) {
      LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, args.room)
    }
    if (location.pathname !== route.roomList) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate(route.roomList)
    }
    context.setStepContext(true)
  }
  
  return (
    <div className="column70 bookingSteps textCenter posRelative">

{(location.pathname === route.checkout || location.pathname === route.confirmation || location.pathname === route.roomDetail || (location.pathname === route.roomList && cartItems.length > 1)) && <div className="bookingStepBack posAbsolute" onClick={() => {
        if ((location.pathname === route.checkout || location.pathname === route.confirmation || location.pathname === route.roomDetail || (location.pathname === route.roomList && cartItems.length > 1))) {
          if (location.pathname === route.checkout) {
            LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, cartItems.length)
          }
          else if (LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM) > 1) {
            LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, currentRoom - 1)
          }
          navigateToRoomInfo(cartItems[LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM) - 1])
        } else navigate(-1)
      }}>
        <img src={arrowLeftIcon} alt="Back Step" width="16" height="13" />
      </div>}
      <div className="bookingStepNumber posAbsolute">
      {location.pathname === route.checkout ? cartItems.length + 1 : location.pathname === route.checkout ? cartItems.length + 2 : currentRoom} / {cartItems.length + 2}
      </div>

      <ul className="dFlex justifyContentCenter">
        {cartItems.length > 0 &&
          cartItems.map((item, index) => {
            const roomNo = index + 1;
            return (
              <li key={index} className={`${(item?.room_info?.rate_name) ? "activeStep" : ""}${((roomStepSelected?.room||currentRoom) == roomNo && location.pathname !== route.checkout && location.pathname !== route.confirmation) ? " activeStep currentActiveStep" : ""}`}>
                <span className={`dBlock mLRAuto ${context.overlay?"cursorDisable":"cursorPointer"}`} 
                onClick={()=>navigateToRoomInfo(item)}>{roomNo}</span>
                <div onClick={()=>!context.overlay&&navigateToRoomInfo(item)}>
                  {t("room")} {roomNo}
                  </div>
              </li>
            );
          })}
        <li className={`${(location.pathname === route.checkout || location.pathname === route.confirmation) ? "activeStep" : ""}${(location.pathname === route.checkout) ? " currentActiveStep" : ""}`} >
        <span className="dBlock mLRAuto">{cartItems?.length + 1}</span>
        <div>{t("personal_details")}</div>
        </li>
        <li className={`${(location.pathname === route.confirmation) ? "activeStep" : ""}${(location.pathname === route.confirmation) ? " currentActiveStep" : ""}`}>
          <span className="dBlock mLRAuto">{cartItems?.length + 2}</span>
          <div>{t("confirmation")}</div>
        </li>
      </ul>
    </div>
  );
};

export default BookingSteps;
