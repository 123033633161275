import React, { memo, useContext, useEffect, useState } from "react";
import { KEYS } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import AppContext from "../../utils/appContext";
import { capitalizeStr, currencyDisplay } from "../../utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import Moment from "moment";
import { t } from "i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { route } from "../../utils/route";
import RemoveSelectedRoomModal from "../common/Modal/removeSelectedRoomModal";
import TaxesFeesBreakdown from "./TaxesFeesBreakdown";

const CartDetails = ({
	roomNo,
	item,
	cartItems,
	removeRoom,
	openRoomEdit,
	tifOptions,
	checkindate,
	checkoutdate,
	roomCost,
	isTaxInclusive,
	totalTaxAmount,
	getRoomList,
	curr,
}) => {
	const [fade, setFade] = useState(false);
	let checkin = Moment(checkindate, "YYYY-MM-DD");
	let checkout = Moment(checkoutdate, "YYYY-MM-DD");
	let totalNights = checkout.diff(checkin, "days");
	let totalNightStayWithString =
		totalNights > 1
			? totalNights + " " + t("nights")
			: totalNights + " " + t("night");

	const context = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [viewEnhancement, setViewEnhancement] = useState(false);
	const [viewPriceBreakDown, setViewPriceBreakDown] = useState(false);
	const [viewTaxesFeesBreakdown, setViewTaxesFeesBreakdown] = useState(true);
	const currencySign =
		curr ||
		(LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
			getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
			? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
			: LocalStorage.get(KEYS.SELECTED_CURRENCY) + " ");

	const removeEnhancement = (id, roomId) => {
		const updatedCart = cartItems.map((item) => {
			if (item.room_info.room_id !== roomId) {
				return item;
			}
			const filteredEnhancements = item.enhancement.map((enh) => {
				if (enh.id === id && enh.count) {
					return {
						...enh,
						count: enh.count - enh.count,
					};
				}
				return enh;
			});
			return {
				...item,
				enhancement: [...filteredEnhancements],
			};
		});
		context.setCartData(updatedCart);
		SessionStorage.setJSON(KEYS.ROOM_DATA, updatedCart);
		context.setEnhancementChange(true);
	};

	const triggerFade = () => {
		setFade((prevState) => {
			return !prevState;
		});
	};

	const [selectedRoomNoToRemove, setSelectedRoomNoToRemove] =
		React.useState(null);
	const [openSelectedRemoveRoomModal, setOpenSelectedRemoveRoomModal] =
		useState(false);

	const removeSelectedRoom = (roomNo) => {
		setOpenSelectedRemoveRoomModal(true);
		context.setRoomStepSelected({
			"room": roomNo,
			"adults": 1,
			"childrens": [],
			"price": [],
			"room_info": {
				"room_id": 0,
				"rate_id": 0,
				"room_name": "",
				"rate_name": ""
			},
			"enhancement": []
		})
	};

	const removeRooms = () => {
		///cartItems.splice((roomNo-1), 1); // 2nd parameter means remove one item only
		cartItems[roomNo - 1].price = [];
		cartItems[roomNo - 1].room_info = {
			room_id: 0,
			rate_id: 0,
			room_name: "",
			rate_name: "",
		};
		cartItems[roomNo - 1].enhancement = [];

		SessionStorage.setJSON(KEYS.ROOM_DATA, cartItems);
		//setopenRemoveRoom(false);
		window.scrollTo(0, 0);
		getRoomList();

		if (
			location.pathname === route.checkout ||
			location.pathname == route.roomDetail
		) {
			navigate(route.roomList);
		}
	};
	let addonsAvailable = false;
	item &&
		item.enhancement &&
		item.enhancement.length &&
		item.enhancement?.map((x) =>
			Array.from({ length: x.count }).map((_, index) => {
				addonsAvailable = true;
			})
		);

	return (
		<div className='columnWrap cartSidebarRow sidebarRoomList paddingTB20' id={`Room ${roomNo}`}>
			<div className='column80 pL0'>
				<small className='dBlock textUppercase'>{t("room")} {roomNo}</small>
				{item.adults} {item.adults > 1 ? t("adults") : t("adult")}
				{item.childrens.length > 0
					? ", " +
					item.childrens.length +
					(item.childrens.length > 1
						? " " + t("children")
						: " " + t("child"))
					: ""}
			</div>
			<div className='column20 textRight pR0'>
				{cartItems.length > 1 ? (
					<a
						className='removeRoom closeIcon dInlineBlock'
						tabIndex='0'
						href='javascript:void(0)'
						onClick={() => {
							removeRoom(roomNo);
						}}
						aria-label={t("remove_room")}
					>
						<span class='hideTag'>{t("remove_room")}</span>
					</a>
				) : (
					""
				)}
				<br />
				<button
					className='editRoom dInlineBlock'
					tabIndex='0'
					onClick={() => openRoomEdit(roomNo)}
					aria-label='Edit Guests'
				>
					{t("edit")}
				</button>
			</div>

			{item?.room_info?.rate_id > 0 ? (
				<>
					<div className='column100 afterAddtoCart pL0 pR0 dFlex justifyContentSpBtw'>
						<div className='productName'>
							{capitalizeStr(item.room_info.room_name)} |{" "}
							{capitalizeStr(item.room_info.rate_name).replace(/\\'/g, "'")}
						</div>
						<span
							className='editCartRoom dInlineBlock'
							tabIndex='0'
							onClick={() => {
								removeSelectedRoom(roomNo);
							}}
							// onClick={() => removeRoom(roomNo)}
							aria-label='Edit Room'
						>
							{t("edit")}
						</span>
					</div>
					{viewPriceBreakDown ? (
						<div
							className={
								fade
									? "fadedClass column100 cartPriceBreak"
									: "visibleClass column100 cartPriceBreak"
							}
							onAnimationEnd={triggerFade}
						>
							<p className='m0'>{t("stay_breakdown")}:</p>
							<ul className='optionEnhance'>{tifOptions}</ul>
						</div>
					) : (
						<></>
					)}
					<div
						className='column100 afterAddtoCart pL0 pR0 dFlex justifyContentSpBtw'
						tabIndex='0'
						onClick={() =>
							setViewPriceBreakDown(!viewPriceBreakDown)
						}
					>
						<div className='productName'>
							{t("total_stay_for")} {totalNightStayWithString}
							{", "}
							{t(item.room_info.tax_status)}:
						</div>
						<div
							className={`cartTotalPrice ${viewPriceBreakDown ? "toggleButton" : ""
								}`}
						>
							{curr ||
								(LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
									getSymbolFromCurrency(
										LocalStorage.get(KEYS.SELECTED_CURRENCY)
									)
									? getSymbolFromCurrency(
										LocalStorage.get(
											KEYS.SELECTED_CURRENCY
										)
									)
									: LocalStorage.get(KEYS.SELECTED_CURRENCY) +
									" ")}
							{roomCost && currencyDisplay(roomCost)}
						</div>
					</div>
					{item?.enhancement?.length && addonsAvailable ? (
						<div className='column100 afterAddtoCart pL0 pR0'>
							{viewEnhancement ? (
								<div className='column100 cartPriceBreak enhanceAddedCart'>
									<p className='m0'>{t("enhancements")}:</p>
									<ul className='optionEnhance'>
										{item.enhancement?.map((x) =>
											x.count > 0 ? (
												<li
													className='dFlex justifyContentSpBtw'
													key={x.id}
												>
													<div className='cartDate'>
														{x.count}
														{" x "}
														{x.name}
													</div>
													<div className='perDayPrice'>
														{curr ||
															(LocalStorage.get(
																KEYS.SELECTED_CURRENCY
															) &&
																getSymbolFromCurrency(
																	LocalStorage.get(
																		KEYS.SELECTED_CURRENCY
																	)
																)
																? getSymbolFromCurrency(
																	LocalStorage.get(
																		KEYS.SELECTED_CURRENCY
																	)
																)
																: LocalStorage.get(
																	KEYS.SELECTED_CURRENCY
																) + " ")}
														{x.price * x.count}
														<a
															aria-label='Remove Enhancement'
															className='removeRoom closeIcon dInlineBlock'
															href='javascript:void(0)'
															style={{
																width: "10px",
																height: "10px",
																marginLeft:
																	"10px",
															}}
															onClick={() =>
																removeEnhancement(
																	x.id,
																	item
																		.room_info
																		.room_id
																)
															}
														></a>
													</div>
												</li>
											) : (
												""
											)
										)}
									</ul>
								</div>
							) : (
								""
							)}

							<div
								className='dFlex justifyContentSpBtw'
								style={{ cursor: "pointer" }}
								onClick={() =>
									setViewEnhancement(!viewEnhancement)
								}
							>
								<div>{t("total_enhancement")}:</div>
								<div
									className={`cartTotalPrice ${viewEnhancement ? "toggleButton" : ""
										}`}
								>
									{curr ||
										(LocalStorage.get(
											KEYS.SELECTED_CURRENCY
										) &&
											getSymbolFromCurrency(
												LocalStorage.get(
													KEYS.SELECTED_CURRENCY
												)
											)
											? getSymbolFromCurrency(
												LocalStorage.get(
													KEYS.SELECTED_CURRENCY
												)
											)
											: LocalStorage.get(
												KEYS.SELECTED_CURRENCY
											) + " ")}
									{currencyDisplay(
										item.enhancement.reduce(
											(accumulator, item) => {
												return (
													accumulator +
													item.price * item.count
												);
											},
											0
										)
									)}
								</div>
							</div>
						</div>
					) : (
						""
					)}
					{/* view price breakdown */}
					{isTaxInclusive === "no" && (
						<TaxesFeesBreakdown
							setViewTaxesFeesBreakdown={
								setViewTaxesFeesBreakdown
							}
							viewTaxesFeesBreakdown={viewTaxesFeesBreakdown}
							currencySign={currencySign}
							totalRoomAmount={currencyDisplay(roomCost)}
							totalTaxAmount={totalTaxAmount}
							tax_obj={item?.room_info?.tax_obj}
						/>
					)}
					{/* view price breakdown */}
				</>
			) : (
				<></>
			)}
			{openSelectedRemoveRoomModal && (
				<RemoveSelectedRoomModal
					room_no={roomNo}
					getRoomList={getRoomList}
					selectedRoomNoToRemove={selectedRoomNoToRemove}
					setSelectedRoomNoToRemove={setSelectedRoomNoToRemove}
					openSelectedRemoveRoomModal={openSelectedRemoveRoomModal}
					setOpenSelectedRemoveRoomModal={
						setOpenSelectedRemoveRoomModal
					}
				/>
			)}
		</div>
	);
};

export default memo(CartDetails);
