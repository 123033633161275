import React, { memo, useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import "moment/locale/fr"; // Required for moment's string translation
import { KEYS, EXT_ICON_URL } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import AppContext from "../../utils/appContext";
import {
	currencyDisplay,
	changeTabindexMinusOneToZero,
	resetFooterTabindex,
} from "../../utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { route } from "../../utils/route";
import { useNavigate, useLocation } from "react-router-dom";
import LockIcon from "../../styles/images/lock-icon.png";
import AddRoomModal from "../common/Modal/addRoomModal";
import RemoveRoomModal from "../common/Modal/removeRoomModal";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import CartDetails from "./cartDetails";
import MobileLogo from "../common/LayoutWrapper/Header/mobileLogo";

const BeCart = (props) => {
	const { t } = useTranslation();
	const context = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();
	const isMobile = window.innerWidth <= 767;
	let totalRoomCost = 0;
	let roomselectioncompleted = 1;
	let addedRooms = 0;
	let btndisabled = 0;
	let showRooms = true;
	let bookingcode = SessionStorage.get(KEYS.BOOKING_CODE)
		? SessionStorage.get(KEYS.BOOKING_CODE)
		: "";
	let cartItems = SessionStorage.getJSON(KEYS.ROOM_DATA);
	let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
	const [localbookingCode, setlocalbookingCode] = useState(bookingcode);
	const [openAddRoom, setopenAddRoom] = useState(false);
	const [everyStayIncludes, setEveryStayInclues] = useState(false);
	const [openRemoveRoom, setopenRemoveRoom] = useState(false);
	const [roomSelection,setRoomSelection]=useState(currentRoom);
	const [removeSelectedRoom, setremoveSelectedRoom] = React.useState(null);
	const [totalCostOpenIncludes, setTotalCostOpenIncludes] = useState(0);
	const [cartPrice, setCartPrice] = useState();
	let totalRoomCostIncludedTaxes = 0;
	let roomBasedTotalTaxAmount = 0;
	let roomBasedTotalRoomCost = 0;
	const cartGrandTotalRef = useRef(0);


	useEffect(()=>{
		setRoomSelection(currentRoom)
	  },[currentRoom])
	
	  useEffect(()=>{
		if(context?.roomStepSelected?.room)setRoomSelection(context?.roomStepSelected?.room)
	  },[context?.roomStepSelected])

	useEffect(() => {
		if (bookingcode) {
			setlocalbookingCode(bookingcode)
		}
	}, [bookingcode])

	useEffect(()=>{
		for (var i = 0; i < cartItems.length; i++) {
			if (cartItems[i].room_info.room_id == "") {
				if (!context.stepContext) LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, i + 1);
				currentRoom = i + 1;
				setRoomSelection(i + 1)
				break;
			}
		}
	},[cartItems])

	let openCal = () => {
		props.openCalendar();
	};

	let openRoomEdit = (roomno) => {
		props.hideRoomCountFunc();
		props.setshowSingleRoom(true);
		props.openRoomEdit(roomno);
	};

	let openCodeSection = () => {
		props.openCodeSection();
	};

	const goToCheckout = () => {
		context.setStepContext(false)
		context.setYourStay(false);
		navigate(route.checkout);
	};

	const removeCode = () => {
		SessionStorage.set(KEYS.BOOKING_CODE, "");
		setlocalbookingCode("");

		let roomData = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));

		//sessionStorage.setItem("roomData", roomData);
		roomData.length > 0 &&
			roomData.map((item, index) => {
				roomData[index].price = [];
				roomData[index].room_info = {
					room_id: 0,
					rate_id: 0,
					room_name: "",
					rate_name: "",
				};
				roomData[index].enhancement = [];
			});
		SessionStorage.setJSON(KEYS.ROOM_DATA, roomData);
		props.getRoomList();
		props.getRoomList("", true)
		if (
			location.pathname === route.checkout ||
			location.pathname === route.roomDetail
		) {
			navigate(route.roomList);
		}
	};

	const openeverystayincludes = () => {
		let stayIncludeOpenCost = 0;
		let enhancementPrice = 0;
		cartItems.map((item, index) => {
			if (item?.price) {
				enhancementPrice = item?.enhancement?.reduce(
					(accumulator, item) => {
						return accumulator + item.price * item.count;
					},
					0
				);
				if (location.pathname !== route.checkout) {
					for (const [key, value] of Object.entries(item.price)) {
						stayIncludeOpenCost += value;
					}
				} else {
					if (cartPrice?.rooms) {
						let details = cartPrice?.rooms?.find(
							(x) =>
								x.room_id === item.room_info.room_id &&
								x.rate_id === item.room_info.rate_id &&
								item.adults == x.adult &&
								item.childrens.length == x.child
						)?.daily_prices?.occupancies;
						for (const [key, value] of Object.entries(
							Object.values(details)[0]
						)) {
							stayIncludeOpenCost += value.rate;
						}
					}
				}
			}
		});
		let updatedPrice =
			Number(stayIncludeOpenCost || 0) + Number(enhancementPrice || 0);
		setTotalCostOpenIncludes(updatedPrice);
		setEveryStayInclues(!everyStayIncludes);
	};

	const removeRoom = (room_no) => {
		setremoveSelectedRoom(room_no);
		setopenRemoveRoom(true);
	};

	const selectRoom = (arg) => {
		// Trap tab focus
		document.getElementById("siteLogo").focus(); // Set focus on site's logo
		const roomListDivElement = document.getElementById("roomList");
		changeTabindexMinusOneToZero(roomListDivElement);
		const footerDivElement = document.getElementById("mainFooter");
		resetFooterTabindex(footerDivElement);
		// Trap tab focus
		window.scrollTo(0, 0);
		context.setRoomStepSelected(context.cartData.find(room=>room.room===arg))
		context.setYourStay(false);
		props.getRoomList();
		if (
			location.pathname === route.checkout ||
			location.pathname == route.roomDetail
		) {
			navigate(route.roomList);
		}
	};

	// Display 'inclusive OR exclusive tax' text in cart's bottom section
	let is_inclusive_of_vat = () => {
		if (SessionStorage.get(KEYS.IS_TAX_INCLUSIVE) === "no") {
			return t("exclusive_of_vat");
		} else {
			return t("inclusive_of_vat");
		}
	};

	let isTaxInclusive = sessionStorage.getItem("isTaxInclusive"); // check if any taxes are exclusive

	for (var i = 0; i < cartItems.length; i++) {
		if (cartItems[i].room_info.room_id == "") {
			roomselectioncompleted = 0;
		} else {
			addedRooms = addedRooms + 1;
		}
	}

	for (var i = 0; i < cartItems.length; i++) {
		if (cartItems[i].room_info.room_id == "") {
			if (!context.stepContext)LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, i + 1);
			currentRoom = i + 1;
			break;
		}
	}

	if (cartItems.length === addedRooms) {
		roomselectioncompleted = 1;
	}

	if (cartItems.length - 1 === addedRooms) {
		roomselectioncompleted = 1;
		if (context.overlay === true) {
			roomselectioncompleted = 0;
		}
	}

	if (cartItems[currentRoom - 1].room_info.room_id == "") {
		btndisabled = 1;
	}

	if (context.overlay === true) {
		btndisabled = 0;
	}

	if (cartItems?.length >= props?.siteConfig?.allow_no_rooms) {
		showRooms = false;
	}

	let hotelCheckInTime = props?.hotelData?.check_in
		? props?.hotelData?.check_in
		: "15:00";
	let hotelCheckOutTime = props?.hotelData?.check_out
		? props?.hotelData?.check_out
		: "12:00";
	// Change Day & Month Sting dynamically for moment lib
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	moment.locale(language);

	const removeModification = () => {
		SessionStorage.set("is_reservation", "");
		SessionStorage.set("is_modification", "");
		window.scrollTo(0, 0);
		navigate(route.roomList);
	};

	let modificationstatus = SessionStorage.get("is_modification");
	let isReservation = SessionStorage.getJSON(KEYS.IS_RESERVATION);

	useEffect(() => {
		if (props.checkoutData) {
			setCartPrice(props.checkoutData);
		}
	}, [props.checkoutData]);

	let dateFormat = language === "fr" ? "ddd DD MMM YYYY" : "ddd, DD MMM YYYY";
	function bodyToggleClass() {
		var bodyElement = document.body;
		bodyElement.classList.remove("bodyHiddenMobile");
	}

	useEffect(() => {
		if (context.hotelData) {
			if (
				context.hotelData.valid_code &&
				localbookingCode &&
				context.codeAdded === true &&
				context.hotelData.code_used != ""
			) {
				SessionStorage.set(KEYS.BOOKING_CODE, localbookingCode);
				props.setValidBookingCode(false);
				let roomData = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));

				//sessionStorage.setItem("roomData", roomData);
				roomData.length > 0 &&
					roomData.map((item, index) => {
						roomData[index].price = [];
						roomData[index].room_info = {
							room_id: 0,
							rate_id: 0,
							room_name: "",
							rate_name: "",
						};
						roomData[index].enhancement = [];
					});
				SessionStorage.setJSON(KEYS.ROOM_DATA, roomData);
			} else if (
				!context.hotelData.valid_code &&
				context.codeAdded === true
			) {
				props.setValidBookingCode(true);
				props.openCodeSection();
				SessionStorage.remove(KEYS.BOOKING_CODE);
				//context.setAddedCode(false)
				if (context.oldCode === true) {
					let roomData =
						'[{"room":1,"adults":1,"childrens":[],"price":[],"room_info":{"room_id":0, "rate_id":0, "room_name": "", "rate_name":""}}]';
					//sessionStorage.setItem("roomData", roomData);
					context.setOldCode(false);
				}
			}
		}
	}, [context.hotelData, context.codeAdded]);

	const cartGrandTotalIncludingTaxes = (roomCost, totalTaxAmount) => {
		roomBasedTotalRoomCost += roomCost;
		roomBasedTotalTaxAmount += totalTaxAmount;
		cartGrandTotalRef.current = roomBasedTotalRoomCost + roomBasedTotalTaxAmount;
	}

	return (
		<>
			<div className='mobileCartLogo textCenter'>
				<MobileLogo siteId={props?.siteConfig?.site_id} />
			</div>
			<div
				className='viewStayBtn textUppercase posRelative'
				onClick={() => {
					context.setYourStay(false);
					bodyToggleClass();
				}}
			>
				{t("view_your_stay")}
			</div>
			<div
				className={`cartSidebar ${everyStayIncludes ? "mobileCartSidebarNew" : ""
					}`}
			>
				<div className='cartRoomInfoSidebar'>
					<div className='cartSidebarTop posRelative'>
						{modificationstatus === "1" ? (
							<>
								<span className='reservationModfication dBlock posAbsolute'>
									{t("reservation_number")}:{" "}
									{isReservation.reservation_id}
									<a
										className='reservationModficationCancel closeIcon dInlineBlock posAbsolute'
										href='Javascript:void(0)'
										onClick={() => removeModification()}
									></a>
								</span>
							</>
						) : (
							""
						)}
						{!everyStayIncludes ? (
							<>
								<h2 className='m0 paddingTB25 textUppercase posRelative'>
									{t("your_stay")}
								</h2>
								<div className='sidebarOverflowHidden'>
									<div className='sidebarRoomListWrap paddingB15'>
										<div className='columnWrap cartSidebarRow sidebarDate paddingB20'>
											<div className='column40 pL0 pR0'>
												<small className='dBlock textUppercase'>
													{t("check_in")}
												</small>
												<span className='dBlock checkInDate bookingDate'>
													{moment(SessionStorage.get(KEYS.CHECK_IN_DATE)).format(`${dateFormat}`)}
												</span>
												<span className='dBlock timeInBtw'>
													{t("after")}{" "}
													{hotelCheckInTime}
												</span>
											</div>
											<div className='column40 pL0 pR0'>
												<small className='dBlock textUppercase'>
													{t("check_out")}
												</small>
												<span className='dBlock checkOutDate bookingDate'>
													{moment(SessionStorage.get(KEYS.CHECKOUT_DATE)).format(`${dateFormat}`)}
												</span>
												<span className='dBlock timeInBtw'>
													{t("before")}{" "}
													{hotelCheckOutTime}
												</span>
											</div>
											<div className='column20 textRight pR0'>
												<button
													className='editDate'
													tabIndex='0'
													onClick={() => openCal()}
													aria-label='Edit Reservation Date'
													id='cartEditDate'
												>
													{t("edit")}
												</button>
											</div>
										</div>
										{cartItems.length > 0 &&
											cartItems.map((item, index) => {
												let roomNo = index + 1;
												const tifOptions = [];
												let roomCost = 0;
												let finalCost = 0;
												// get total tax amount of room
												let totalTaxAmount = 0;
												const tax_obj = item?.room_info?.rate_obj?.tax;
												for (const key in tax_obj) {
													if (tax_obj.hasOwnProperty(key)) {
														totalTaxAmount += location.pathname !== route.checkout ? tax_obj[key].tax_amount_user_currency : tax_obj[key].tax_amount;
													}
												}
												// get total tax amount of room
												if (location.pathname === route.checkout) {
													if (cartPrice?.rooms) {
														let curr = cartPrice.hotel.abbrevation && getSymbolFromCurrency(cartPrice.hotel.abbrevation);
														let details = cartPrice?.rooms?.find((x) =>
															x.room_id === item.room_info.room_id && x.rate_id === item.room_info.rate_id
															&& item.adults == x.adult && item.childrens.length == x.child
														)?.daily_prices?.occupancies;
														// delete the policies obj, if found in details
														for (const key in details) { // Iterate through each child in details
															if (details[key] && details[key].policies) {
																// Check if 'policies' key exists in the child and delete it if it does
																delete details[key].policies;
															}
														}
														// delete the policies obj, if found in details
														let enhancementPrice = 0;
														if (details && Object.values(details).length > 0) {
															for (const [key, value,] of Object.entries(Object.values(details)[0])) {
																tifOptions.push(
																	<li className='dFlex justifyContentSpBtw'>
																		<day className='cartDate'>
																			{moment(key).format("ddd DD MMM YYYY")}
																		</day>
																		<div className='perDayPrice'>
																			{curr}{""}{currencyDisplay(value.rate)}
																		</div>
																	</li>
																);
																enhancementPrice = item?.enhancement?.reduce((accumulator, it) => {
																	return (accumulator + it.price * it.count);
																}, 0);
																finalCost += value.rate;
																roomCost += value.rate;
															}
														}
														totalRoomCost += finalCost + (enhancementPrice || 0);
														totalRoomCostIncludedTaxes += finalCost + (enhancementPrice || 0) + totalTaxAmount;

														// Cart's Grand Total Calculation
														cartGrandTotalIncludingTaxes(roomCost, totalTaxAmount)

														return (
															<CartDetails
																roomNo={roomNo}
																item={item}
																cartItems={cartItems}
																removeRoom={removeRoom}
																openRoomEdit={openRoomEdit}
																tifOptions={tifOptions}
																checkindate={props.checkindate}
																checkoutdate={props.checkoutdate}
																roomCost={roomCost}
																isTaxInclusive={isTaxInclusive}
																totalTaxAmount={totalTaxAmount}
																getRoomList={props.getRoomList}
																curr={curr}
															/>
														);
													}
												} else if (item?.price) {
													let curr =
														LocalStorage.get(
															KEYS.SELECTED_CURRENCY
														) &&
															getSymbolFromCurrency(
																LocalStorage.get(
																	KEYS.SELECTED_CURRENCY
																)
															)
															? getSymbolFromCurrency(
																LocalStorage.get(
																	KEYS.SELECTED_CURRENCY
																)
															)
															: LocalStorage.get(
																KEYS.SELECTED_CURRENCY
															) + " ";
													for (const [
														key,
														value,
													] of Object.entries(
														item.price
													)) {
														tifOptions.push(
															<li className='dFlex justifyContentSpBtw'>
																<day className='cartDate'>
																	{moment(
																		key
																	).format(
																		"ddd DD MMM YYYY"
																	)}
																</day>
																<div className='perDayPrice'>
																	{curr}
																	{""}
																	{currencyDisplay(
																		value
																	)}
																</div>
															</li>
														);
														let enhancementPrice =
															item?.enhancement?.reduce(
																(
																	accumulator,
																	item
																) => {
																	return (
																		accumulator +
																		item.price *
																		item.count
																	);
																},
																0
															);
														totalRoomCost +=
															value +
															(enhancementPrice ||
																0);
														roomCost += value;
													}
													totalRoomCostIncludedTaxes =
														totalRoomCost + totalTaxAmount;
												}

												// Cart's Grand Total Calculation
												cartGrandTotalIncludingTaxes(roomCost, totalTaxAmount)

												return (
													<CartDetails
														roomNo={roomNo}
														item={item}
														cartItems={cartItems}
														removeRoom={removeRoom}
														openRoomEdit={openRoomEdit}
														tifOptions={tifOptions}
														checkindate={props.checkindate}
														checkoutdate={props.checkoutdate}
														roomCost={roomCost}
														isTaxInclusive={isTaxInclusive}
														totalTaxAmount={totalTaxAmount}
														getRoomList={props.getRoomList}
													/>
												);
											})}

										<div className='columnWrap cartSidebarRow sidebarAddValues paddingTB20'>
											<div className='column50 pL0'>
												{localbookingCode ? (
													<div className='codeAdded dInlineBlock'>
														<a
															aria-label='Code Added'
															role='button'
															className='addCode'
															href='Javascript:void(0)'
															onClick={() =>
																openCodeSection()
															}
														>
															{localbookingCode}
														</a>{" "}
														<a
															aria-label='Remove Code'
															role='button'
															className='removeCode closeIcon dInlineBlock'
															href='Javascript:void(0)'
															onClick={() =>
																removeCode()
															}
														></a>
													</div>
												) : (
													<a
														aria-label={t(
															"add_code"
														)}
														role='button'
														className='addCode plusIcon'
														href='Javascript:void(0)'
														onClick={() =>
															openCodeSection()
														}
													>
														{t("add_code")}
													</a>
												)}
											</div>
											{SessionStorage.getJSON(
												KEYS.IS_RESERVATION
											) &&
												SessionStorage.get(
													"is_modification"
												) ? (
												""
											) : (
												<>
													{showRooms ? (
														<div className='column50 textRight pR0'>
															<a
																aria-label='Add Room'
																role='button'
																className='addRoom plusIcon'
																href='Javascript:void(0)'
																onClick={() =>
																	setopenAddRoom(
																		true
																	)
																}
															>
																{t("add_room")}
															</a>
														</div>
													) : (
														""
													)}
												</>
											)}
										</div>
									</div>
								</div>
							</>
						) : (
							""
						)}
						<div
							className={`sidebarStayWrap ${everyStayIncludes ? "showSidebarStayPopup" : ""
								}`}
						>
							{everyStayIncludes ? (
								<div className='sidebarStayPopup'>
									{parse(
										(
											props?.checkoutData?.hotel
												?.stay_includes
												?.stay_description ??
											props?.hotelData?.stay_includes
												?.stay_description
										)?.replace(
											new RegExp("/UserFiles", "g"),
											EXT_ICON_URL
										) ?? ""
									)}
								</div>
							) : (
								""
							)}
							<button
								className='sidebarStayBtn customBtn customBtnTransparent w100 posRelative'
								tabIndex='0'
								onClick={() => openeverystayincludes()}
							>
								{t("every_stay_includes")}
							</button>
						</div>
					</div>
					<div className='cartSidebarBottom'>
						<div className='columnWrap sidebarCartTotal paddingTB20'>
							<div className='column40 pL0'>
								<strong className='textUppercase'>
									{t("booking_Total")}
								</strong>
							</div>
							<div className='column60 textRight pR0'>
								<span className='dBlock sidebarCartPrice'>
									{(location.pathname == route.checkout &&
										cartPrice?.hotel?.abbrevation &&
										getSymbolFromCurrency(
											cartPrice.hotel.abbrevation
										)) ||
										(LocalStorage.get(
											KEYS.SELECTED_CURRENCY
										) &&
											getSymbolFromCurrency(
												LocalStorage.get(
													KEYS.SELECTED_CURRENCY
												)
											)
											? getSymbolFromCurrency(
												LocalStorage.get(
													KEYS.SELECTED_CURRENCY
												)
											)
											: LocalStorage.get(
												KEYS.SELECTED_CURRENCY
											))}
									{isTaxInclusive === "yes"
										? !everyStayIncludes
											? totalRoomCost &&
											currencyDisplay(totalRoomCost)
											: totalCostOpenIncludes &&
											currencyDisplay(
												totalCostOpenIncludes
											)
										: currencyDisplay(
											cartGrandTotalRef.current
										)}
								</span>
								<small className='dBlock'>
									{isTaxInclusive === "yes"
										? t("inclusive_of_vat")
										: t("inclusive_of_taxes_fees")}
								</small>
							</div>
						</div>
						<div className='cartSidebarBtn'>
							{location.pathname !== route.checkout ? (
								roomselectioncompleted == 0 ? (
									<button
										className={`customBtn customBtnDark w100  ${btndisabled === 1
											? "btnDisable"
											: ""
											}`}
										tabIndex='0'
										disabled={
											btndisabled === 1 ? true : false
										}
										onClick={() => selectRoom(roomSelection)}
									>
										{t("select_room")} {roomSelection}
									</button>
								) : (
									<button
										className={`customBtn customBtnDark w100  ${btndisabled === 1 &&
											isMobile !== true
											? "btnDisable"
											: ""
											}`}
										tabIndex='0'
										disabled={
											btndisabled === 1 &&
												isMobile !== true
												? true
												: false
										}
										onClick={() =>
											isMobile && btndisabled === 1
												? context.setYourStay(false)
												: goToCheckout()
										}
									>
										{t("continue_to_book")}
									</button>
								)
							) : (
								<button
									className='customBtn customBtnDark w100'
									tabIndex='0'
									onClick={() => {
										props.onSubmitCheckout();
										context.setYourStay(false);
									}}
								>
									{t("complete_booking")}{" "}
									<img
										className='vAlignTextBaseline'
										src={LockIcon}
										alt='Lock Icon'
										width='14'
									/>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<AddRoomModal
				openAddRoom={openAddRoom}
				setopenAddRoom={setopenAddRoom}
				getRoomList={props.getRoomList}
				siteConfig={props.siteConfig}
			/>
			<RemoveRoomModal
				room_no={removeSelectedRoom}
				openRemoveRoom={openRemoveRoom}
				setopenRemoveRoom={setopenRemoveRoom}
				getRoomList={props.getRoomList}
			/>
		</>
	);
};

export default memo(BeCart);
