import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { capitalizeStr, currencyDisplay } from '../../utils/utils';
import { LocalStorage } from "../../utils/storage";
import { useTranslation } from "react-i18next";
import RoomImages from "./roomImages";
import getSymbolFromCurrency from "currency-symbol-map";
import { SITE_ID_LIST_HASH } from "../../utils/utils";
import { KEYS } from "../../utils/constant";
import { floorPlanUrl, roomDescriptionDisplay } from "../../utils/utils";
import Iframe from "react-iframe";
import BedIcon from "../../styles/images/bed-icon.png";
import UserIcon from "../../styles/images/user-icon.png";
import SizeIcon from "../../styles/images/size-icon.png";
import RoomView from "../../styles/images/room-view-icon.png";
import ConnectingRoomIcon from "../../styles/images/connecting-rooms.svg";
import parse from "html-react-parser";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 850,
    p: 0,
};

function RoomDetailOverlay({ open, handleClose, item, activeRoom, siteConfig, showHideRates, viewBy, minimumSum, showAddToStay, setOpenEnquiry }) {
    const [isActive, setIsActive] = useState({ about_room: false, floor_plan: false, tour_360: false });
    const [aboutRoom, setAboutRoom] = useState();
    const [roomItems, setRoomItems] = useState();
    const { t } = useTranslation();
    const viewRoomAboutCollapse = (arg) => {
        for (let key in isActive) {
            isActive[key] = key === arg ? !isActive[key] : false;
        }
        setIsActive({ ...isActive });
    }

    const room360TourURL = item?.group_room ? activeRoom?.enq_360?.replace(/ /g, "%20") : item?.enq_360?.replace(/ /g, "%20");

    const details = (keyHighlight) => {
        
        const descData = roomDescriptionDisplay(keyHighlight);
        let bedData = descData?.[0];
        const sleepData = descData?.[1];
        const roomView = descData?.[2];
        // const roomView = "sea_view,road_view,pool_view,";
        const sizeData = descData?.[3];
        const freetext = descData?.[4];
        let keyhightlightcount = 0;

        // Sanitize 'Room view' data
        let roomViewArr = roomView.split(/[:,]/); // break 'string' from ':' & ',' and convert it into array 
        roomViewArr = roomViewArr.filter((elm) => elm); // remove empty values from array
        let roomViewStr = '';   // Add 'room view' text as prefix

        roomViewArr?.map((element, index) => {
            element = element.trim(); // remove space from start & end of the string
            if (index > 1 && index <= roomViewArr.length) { // if element's index is not equal to first, second and last
                roomViewStr += ", ";
            }
            if (element) {
                roomViewStr += t(element);
            }
            if (index === 0) { // add ':' after first element to consider as Title
                roomViewStr += ": ";
            }
            return roomViewStr;
        });
        // Sanitize 'Room view' data


        return (
            <ul className='roomAmenities paddingB25'>
                {bedData && (
                    <li key='bed'>
                        <img src={BedIcon} alt='Bed' width='16' />
                        {bedData}
                    </li>
                )}
                {sleepData && (
                    <li key='user'>
                        <img src={UserIcon} alt='Users' width='16' />
                        {sleepData}
                    </li>
                )}
                {sizeData && (
                    <li key='size'>
                        <img src={SizeIcon} alt='Size' width='16' />
                        {sizeData}
                    </li>
                )}
                {/* Room View */}
                {/* {console.log("roomView -> " + JSON.stringify(roomView))} */}
                {roomView && (
                    <li key='view'>
                        <img src={RoomView} alt='Size' width='16' />
                        {`${roomViewStr} ${keyHighlight?.room_attr?.additional_text ? keyHighlight?.room_attr?.additional_text : ""}`}
                    </li>
                )}
                {/* Room View */}
                {freetext && (
                    <li>
                        <img
                            src={ConnectingRoomIcon}
                            alt={t("connecting_room")}
                            width='16'
                        />
                        {freetext}
                    </li>
                )}
            </ul>
        );
    };

    useEffect(() => {

        // Create a new DOM parser
        const parser = new DOMParser();

        const doc = parser.parseFromString(activeRoom?.room_desc || item?.room_desc, 'text/html');

        const ulElement = doc.querySelector('ul');

        const tempDiv = document.createElement('div');
        const parseDec = (arg) => {
            return arg
                ?
                arg.replace(
                    new RegExp("/UserFiles", "g"),
                    process.env.REACT_APP_EXT_ICON_URL
                )
                : ""
        }
        tempDiv.innerHTML = parseDec(activeRoom?.room_desc || item?.room_desc)?.replace(/[\n\t]|&nbsp;/g, '');

        const pTags = tempDiv.querySelectorAll('*:not(ul):not(ul *)');

        const paragraphs = Array.from(pTags).filter(p=>p?.innerText).map(p => <><p style={{ textAlign: "left" }}>{parse(p?.innerText)}</p></>)        

        setAboutRoom(paragraphs)


        if (ulElement) {
            // Extract text content from <ul> and split into list items
            const items = Array.from(ulElement.querySelectorAll('li')).map(li => li.textContent);
            setRoomItems(items);
        }
    }, [item?.room_desc, activeRoom?.room_desc]);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                className='viewRoomModalPopup'
                role="dialog"
                aria-modal="true"
                style={{
                    bottom: "-100%",
                    top: "auto",
                  }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant='h6' component='h2' className="textCenter posRelative">
                        {activeRoom?.room_name || item?.room_name}
                        <button
                            type='button'
                            className='mfp-close'
                            onClick={() => handleClose()}
                            aria-level='Close Button'
                        >
                            Close
                        </button>
                    </Typography>

                    <div className="viewDetailPopupContent">
                        <RoomImages
                            from="overlay"
                            roomImage={item?.group_room !== 1 ? item.room_images : activeRoom?.room_images}
                            roomDetails={item?.group_room !== 1 ? item : activeRoom}
                        />

                        <div className="white-popup-block">

                            <div className="columnWrap posRelative">
                                <div className="column55 viewDetailTopLeft">
                                    <div className="viewDetailText">
                                        <h3>{activeRoom?.room_name || item?.room_name}</h3>
                                        <div className="viewDetailTextLarge" dangerouslySetInnerHTML={{
                                            __html: activeRoom?.room_short_desc || item?.room_short_desc,
                                        }} ></div>
                                    </div>
                                </div>
                                <div className="column45 viewDetailTopRight">
                                    <div className="viewDetailText">
                                        <h3>{t("details")}</h3>
                                        {item?.group_room !==1 && item?.key_highlights && details(item?.key_highlights)}
                                        {item?.group_room ===1 && activeRoom?.key_highlights && details(activeRoom?.key_highlights)}
                                    </div>
                                </div>
                            </div>
                            <div className="columnWrap posRelative festureText">
                                <div className="column100">
                                    <div className="viewDetailText">
                                        <h3 id="include_with_your_stay">{t("include_with_your_stay")}</h3>
                                        <ul>
                                            {roomItems?.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {aboutRoom && <div className="columnWrap posRelative">
                                <div className="column100">
                                    <div className={isActive.about_room ? 'viewDetailText viewDetailAccordian viewRoomAboutOpen' : 'viewDetailText viewDetailAccordian'}>
                                        <h3 onClick={() => viewRoomAboutCollapse("about_room")}>
                                            {t("about_the_room")}
                                            <span className="viewCollapseIcon"></span>
                                        </h3>
                                        <div className="aboutRoomCollapse"  >
                                            {aboutRoom}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(activeRoom?.floor_plan?.[0]?.url_path||item?.floor_plan?.[0]?.url_path) && <div className="columnWrap posRelative">
                                <div className="column100">
                                    <div className={isActive.floor_plan ? 'viewDetailText viewDetailAccordian viewRoomAboutOpen' : 'viewDetailText viewDetailAccordian'}>
                                        <h3 onClick={() => viewRoomAboutCollapse("floor_plan")}>
                                            {t("floor_Plan")}
                                            <span className="viewCollapseIcon"></span>
                                        </h3>
                                        <div className="aboutRoomCollapse" style={{textAlign:"center"}}>
                                            <img
                                                className='single-room-floor-plan'
                                                alt={item.room_name}
                                                src={floorPlanUrl(item?.group_room?activeRoom:item)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {room360TourURL && <div className="columnWrap posRelative">
                                <div className="column100">
                                    <div className={isActive?.tour_360 ? 'viewDetailText viewDetailAccordian viewRoomAboutOpen' : 'viewDetailText viewDetailAccordian'}>
                                        <h3 onClick={() => viewRoomAboutCollapse("tour_360")}>
                                            {t("tour_360")}
                                            <span className="viewCollapseIcon"></span>
                                        </h3>
                                        <div className="aboutRoomCollapse">
                                            <Iframe
                                                url={room360TourURL}
                                                width='100%'
                                                height='500px'
                                                frameBorder="0"
                                                id=''
                                                className=''
                                                display='block'
                                                position='relative'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className={`viewDetailFooter dFlex ${item?.sale_type === "0"?"justifyContentEnd":"justifyContentSpBtw"} alignItemsCenter`}>
                        {item?.sale_type !== "0"&&<span className="roomPrice">
                            {item?.group_room === 1 && activeRoom?.min_price && (
                                <span className='bookingFromPrice' style={{ display: "grid" }}>
                                    <span>
                                        {t("from")}{" "}{LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
                                            getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
                                            ? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
                                            : LocalStorage.get(KEYS.SELECTED_CURRENCY)}
                                        {currencyDisplay(activeRoom?.min_price || item?.min_price)}
                                    </span>
                                </span>
                            )}
                            {item?.group_room !== 1 &&
                                ((item?.min_price && !showAddToStay) || (item?.price?.occupancies && showAddToStay)) && (
                                    <span className='bookingFromPrice'>
                                        {siteConfig?.allow_average_price === 1 && t("from")}{" "}
                                        {LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
                                            ? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY)}
                                        {currencyDisplay(minimumSum)}
                                    </span>
                                )}
                            <small className="dBlock"> {`(${SITE_ID_LIST_HASH.mbh?.includes(siteConfig?.site_id?.toString()) ? t("mbh_avg_price_helper_text") : t("average_per_night")})`}</small>
                        </span>}
                        {viewBy === "rooms"&&item?.sale_type !== "0" && <button className="customBtn customBtnDark" type="button" onClick={() => {
                            handleClose()
                            showHideRates("open")
                        }}>{t("view_rates")}</button>}
                        {item?.sale_type === "0" && (
                        <button
                            className='customBtn customBtnDark '
                            tabIndex='0'
                            onClick={() => {
                                setOpenEnquiry(true)
                                handleClose()
                            }}
                        >
                            {t("enquire")}
                        </button>
                    )}

                    </div>
                    
                </Box>
            </Modal >
        </>
    )
}

export default RoomDetailOverlay
