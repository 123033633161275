import React, { useEffect } from "react";
import RoomList from "./../pages/roomList";
import LayoutWrapper from "../components/common/LayoutWrapper";
import { Route, Routes, Navigate, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import RoomDetail from "../pages/roomDetail";
import Checkout from "../pages/checkout";
import Confirmation from "../pages/confirmation";
import AppContext from "../utils/appContext";
import useAppContext from "../hooks/useAppContext";
import { route } from "./route";
import CancelConfirmation from "../pages/cancelConfirmation";
import DeclineBooking from "../pages/declineBooking";
import ManageBookingIndex from "../pages/manageBooking";
import { KEYS } from "./constant";
import FourZeroFour from "../pages/fourZeroFour";
// import InternalErrorPage from "../pages/internalError";
import MaintenancePage from "../pages/maintenance";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/error/ErrorFallback";
import logError from "../components/error/logError";
import { LocalStorage, SessionStorage } from "./storage";
import moment from "moment";
import { useMaintenance } from "../hooks/useMaintenance";
// import { useToken } from "../hooks/useToken";

const MbhRoutes = ({
  hotelList,
  currencyList,
  authToken,
  searchPanelData,
  siteConfig,
  ip,
}) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate();
  const siteId = siteConfig?.site_id || false;
  // const siteId = 31237;

  const { maintenanceMsg, maintenanceData } = useMaintenance({
		authToken,
		siteConfig,
		searchPanelData
  });

//   useToken({searchPanelData});
  
  useEffect(() => {
    if (siteId) {
      import(`./../styles/scss/${siteId}/app.scss`);
    }
  }, [siteId]);
  const value = useAppContext();

  useEffect(() => {
    if (searchParams && searchParams?.size) {
      let lang = searchParams.get("lang")
      let checkIn = searchParams.get("checkin");
      let checkOut = searchParams.get("checkout")
      const currentDate = moment();
      let los = searchParams.get("los") && Number(searchParams.get("los")) > 0 ? Number(searchParams.get("los")) : 1;
      let roomCount = searchParams.get("room") ? parseInt(searchParams.get("room")) : 1;
      let allowNoRooms = parseInt(siteConfig.allow_no_rooms)
      if (roomCount > allowNoRooms) {
        roomCount = allowNoRooms;
      }
      let bookingCode = searchParams.get("bookingcode")

      if (lang) {
        LocalStorage.set(KEYS.LANG, lang)
		LocalStorage.set(KEYS.I18_NEXT_LNG, lang)
      }

      const formatDate = (dateString) => {
        const formattedDate = moment(dateString, ["YYYYMMDD", "DDMMYYYY", "MM-DD-YYYY", "MM/DD/YYYY", "YYYY/MM/DD", "YYYY-MM-DD"], true).format("YYYY-MM-DD");
        return formattedDate !== "Invalid date" ? formattedDate : null;
      }
      
	  if (checkIn) {
        checkIn = formatDate(checkIn);
      }
      
	  if (checkOut) {
        checkOut = formatDate(checkOut);
      }

      if (checkIn && checkOut) {
        const checkInDate = moment(checkIn, "YYYY-MM-DD");
        const checkOutDate = moment(checkOut, "YYYY-MM-DD")
        if (checkOutDate.isBefore(checkInDate)) {
          checkIn = currentDate.format("YYYY-MM-DD")
          checkOut = currentDate.add(1, "days").format("YYYY-MM-DD")
        }
      }
      if (checkIn) {
        const checkInDate = moment(checkIn, "YYYY-MM-DD");
        if (checkInDate.isBefore(currentDate, "day")) {
          SessionStorage.set(KEYS.CHECK_IN_DATE, currentDate.format("YYYY-MM-DD"));
        } else {
          SessionStorage.set(KEYS.CHECK_IN_DATE, checkIn);
        }
      } else if (los && checkOut) {
        const calculatedCheckInDate = moment(checkOut, "YYYY-MM-DD");
        if (calculatedCheckInDate.isSameOrBefore(currentDate, "day")) {
          SessionStorage.set(KEYS.CHECK_IN_DATE, currentDate.format("YYYY-MM-DD"));
        } else {
          SessionStorage.set(KEYS.CHECK_IN_DATE, calculatedCheckInDate.subtract(los, "days").format("YYYY-MM-DD"));
        }
      } else if (checkOut) {
        const calculatedCheckInDate = moment(checkOut, "YYYY-MM-DD").subtract(1, "days");
        if (calculatedCheckInDate.isBefore(currentDate, "day")) {
          SessionStorage.set(KEYS.CHECK_IN_DATE, currentDate.format("YYYY-MM-DD"));
        } else {
          SessionStorage.set(KEYS.CHECK_IN_DATE, calculatedCheckInDate.format("YYYY-MM-DD"));
        }
      }

      if (checkOut) {
        const checkOutDate = moment(checkOut, "YYYY-MM-DD");
        if (checkOutDate.isSameOrBefore(currentDate, "day")) {
          SessionStorage.set(KEYS.CHECKOUT_DATE, currentDate.add(1, "days").format("YYYY-MM-DD"));
        } else {
          SessionStorage.set(KEYS.CHECKOUT_DATE, checkOut);
        }
      } else if (los && checkIn) {
        const calculatedCheckOutDate = moment(checkIn, "YYYY-MM-DD");
        if (calculatedCheckOutDate.isBefore(currentDate, "day")) {
          SessionStorage.set(KEYS.CHECKOUT_DATE, currentDate.add(los, "days").format("YYYY-MM-DD"));
        } else {
          SessionStorage.set(KEYS.CHECKOUT_DATE, moment(checkIn, "YYYY-MM-DD").add(los, "days").format("YYYY-MM-DD"));
        }

      } else if (checkIn) {
        SessionStorage.set(KEYS.CHECKOUT_DATE, moment(checkIn, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"))
      }

      if (roomCount) {
        const count = parseInt(roomCount);
        const roomData = [];
        for (let i = 1; i <= count; i++) {
          const roomObject = {
            room: i,
            adults: searchParams.get(`adult${i}`) ? Number(searchParams.get(`adult${i}`)) : 1,
            childrens: [],
            price: [],
            room_info: {
              room_id: 0,
              rate_id: 0,
              room_name: "",
              rate_name: "",
            },
          };
          const childCount = parseInt(searchParams.get(`child${i}`));
          if (childCount && childCount > 0) {
            roomObject.childrens = Array.from({ length: childCount }, (_, index) => index + 1);
          }
          roomData.push(roomObject);
        }
        SessionStorage.setJSON(KEYS.ROOM_DATA, roomData)
      }
      if (location?.pathname?.split("/")?.[2]) {
        //SessionStorage.set(KEYS.HOTEL_ID, location.pathname.split("/")[2])
      }
      if (bookingCode) {
        SessionStorage.set(KEYS.BOOKING_CODE, bookingCode)
      }
    }
  }, [searchParams], sessionStorage.getItem("hotelId"))

  useEffect(()=>{
	value.setCartData(SessionStorage.getJSON('roomData'))
  },[])

  useEffect(() => {
    const correctedPath = window.location.pathname.replace(/\/{2,}|\/fr\//g, '/');
	if (window.location.pathname.includes('//fr')) {
		const updatedURL = window.location.href.replace(/(\?|&)lang=([^&]*)/, `$1lang=fr`);
		navigate(updatedURL, { replace: true });
	}
    if (window.location.pathname !== correctedPath) {
      const correctedURL = `${correctedPath}${window.location.search}${window.location.hash}`;
      navigate(correctedURL, { replace: true });
    }
  }, [navigate]);
  
  return (
		<>
			{searchPanelData && (
				<AppContext.Provider value={value}>
						<ErrorBoundary
							FallbackComponent={ErrorFallback}
							onError={logError}
						>
						<LayoutWrapper
								hotelList={hotelList}
								currencyList={currencyList}
								authToken={authToken}
								searchPanelData={searchPanelData}
								siteConfig={siteConfig}
								ip={ip}
							>
								<Routes>
									<Route
										path={route.roomList}
										exact
										sensitive
										element={
											<RoomList
												hotelList={hotelList}
												currencyList={currencyList}
												authToken={authToken}
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												ip={ip}
											/>
										}
									/>
									<Route
										path={route.roomListIndex}
										exact
										sensitive
										element={
											<RoomList
												hotelList={hotelList}
												currencyList={currencyList}
												authToken={authToken}
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												ip={ip}
											/>
										}
									/>
									<Route
										path={route.roomDetail}
										exact
										sensitive
										element={
											<RoomDetail
												hotelList={hotelList}
												currencyList={currencyList}
												authToken={authToken}
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												ip={ip}
											/>
										}
									/>
									<Route
										path={route.checkout}
										exact
										sensitive
										element={
											<Checkout
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												authToken={authToken}
											/>
										}
									/>
									<Route
										path={route.confirmation}
										exact
										sensitive
										element={
											<Confirmation
												currencyList={currencyList}
												authToken={authToken}
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												ip={ip}
											/>
										}
									/>
									<Route
										path={route.manageBookings}
										exact
										sensitive
										element={
											<ManageBookingIndex
												siteConfig={siteConfig}
											/>
										}
									/>
									<Route
										path={route.cancelConfirmation}
										exact
										sensitive
										element={
											<CancelConfirmation
												currencyList={currencyList}
												authToken={authToken}
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												ip={ip}
											/>
										}
									/>
									<Route
										path={route.declineBooking}
										exact
										sensitive
										element={
											<DeclineBooking
												currencyList={currencyList}
												authToken={authToken}
												searchPanelData={searchPanelData}
												siteConfig={siteConfig}
												ip={ip}
											/>
										}
									/>
									<Route
										path={route.fourZeroFour}
										element={<FourZeroFour />}
									/>
									<Route
										path='*'
										element={
											<Navigate to={route.fourZeroFour} />
										}
									/>
									<Route
										path={route.maintenance}
										exact
										sensitive
										element={
											<MaintenancePage
												authToken={authToken}
												siteConfig={siteConfig}
												maintenanceMsg={maintenanceMsg}
											/>
										}
									/>
								</Routes>
							</LayoutWrapper>
						</ErrorBoundary>
				</AppContext.Provider>
			)}
		</>
  );
};

export default MbhRoutes;
