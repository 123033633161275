import React, { useContext, useRef, useState } from "react";
import Input from "../common/Input/inputField";
import Select from "../common/Select";
import { KEYS } from "../../utils/constant";
import PhoneInput from '../common/Input/phoneInputField'
import Checkbox from "../common/Checkbox";
import {
	TranslatedPrefix,
	alphabeticName,
	replaceEmoji,
	PREFIX
} from "../../utils/utils";
import { SessionStorage } from "../../utils/storage";
import { useTranslation } from "react-i18next";
import AppContext from "../../utils/appContext";
import { NUMBER_REGEX } from '../../utils/constant'

const GuestInfo = ({
	item,
	index,
	fields,
	stopClose,
	setValue,
	getValues,
	errors,
	register,
	watch,
	checkboxIndex,
	setCheckBoxIndex,
	clearErrors,
	checkoutData,
	trigger,
	bookingData
}) => {
	const { t } = useTranslation();
	let context = useContext(AppContext);
	const [activeTabGuest, setActiveTabGuest] = useState(false);
	const [showEarlyCheckinMessage, setshowEarlyCheckinMessage] =
		useState(false);
	const roomDetail = SessionStorage.getJSON(KEYS.ROOM_DATA);
	const ref = useRef(null);
	let language = localStorage.getItem("i18nextLng")
		? localStorage.getItem("i18nextLng")
		: "en";
	const countUpdate = (fieldId, addonId, increment) => {
		const updatedAddonData = watch("guest").map((item) => {
			if (item.room !== fieldId) {
				return item;
			}
			const updatedAddons = item.addonData.map((add) => {
				if (add.id === addonId && add.room_id == item.room_info.room_id && add.rate_id == item.room_info.rate_id) {
					let inventory = Object.values(add.amount).reduce((acc, curr) => acc + curr.inventory, 0);
					let newCount = (add.count || 0) + (increment ? 1 : -1);

					if (newCount > inventory) {
						newCount = inventory;
					} else if (newCount < 0) {
						newCount = 0;
					}
					const updatedRoomDetail = roomDetail.map((room) => {
						if (!room.enhancement) {
							room.enhancement = [];
						}
						if (room.room === fieldId) {
							const findData = room.enhancement.find((addon) => addon.id === addonId);
							const newPrice = add.addon_type === "one_off" ?
								Object.values(add.amount)?.[0]?.rate
								: Object.values(add.amount).reduce((acc, curr) => acc + curr.rate, 0);
							if (findData) {
								findData.price = newPrice;
								findData.count = newCount;
								findData.name = add.title;
								findData.id = addonId;
								findData.type = add.addon_type;
							} else {
								room.enhancement.push({
									price: newPrice,
									count: newCount,
									name: add.title,
									id: addonId,
									type: add.addon_type
								});
							}
							if (newCount === 0) {
								room.enhancement = room.enhancement.filter(
									(item) => item.id !== addonId
								);
							}
						}
						return room;
					});
					SessionStorage.setJSON(KEYS.ROOM_DATA, updatedRoomDetail);
					context.setRoomData(updatedRoomDetail);
					return { ...add, count: newCount };
				} else {
					return add;
				}
			});
			return { ...item, addonData: updatedAddons };
		});
		setValue("guest", updatedAddonData);
		//   enableScroll()
	};
	let formatString = (e) => {
		setshowEarlyCheckinMessage(false);
		var code = e.keyCode;
		const regex = /^[0-9:]*$/;
		var allowedKeys = [8];
		if (code === 8) {
			return false;
		}
		if (!regex.test(e.target.value)) {
			e.target.value = "";
			return;
		}
		let value = e.target.value;
		if (value.length > 23) {
			value = value.substring(0, 23);
		}
		if (value.length === 2) {
			let hour = value;
			if (hour > 23) {
				hour = 23;
			}
			value = hour.toString() + ":";
		}
		if (value.length > 2) {
			let hour = value.substring(0, 2);
			let minute = value.substring(3);
			minute = minute.replace(/[^\d]/g, "");
			if (minute.length > 2) {
				minute = minute.substring(0, 2);
			}
			minute = minute.replace(/[^\d]/g, "");
			if (parseInt(minute) > 59) {
				minute = "59";
			}
			value = hour.toString() + ":" + minute;
		}
		if (value.length === 5) {
			let earlyval = value.split(":");
			if (earlyval[0] < 15) {
				setshowEarlyCheckinMessage(true);
			} else {
				setshowEarlyCheckinMessage(false);
			}
		} else {
			setshowEarlyCheckinMessage(false);
		}
		e.target.value = value;
	};
	const scrollToTop = () => {
		setActiveTabGuest(!activeTabGuest);
		if (!activeTabGuest) {
			const marginTop = 125;
			const elementTop = ref.current.getBoundingClientRect().top;
			window.scrollBy({
				top: elementTop - marginTop,
				behavior: "smooth",
			});
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter" && event.target.tagName === "DIV") {
			// setActiveTabGuest(!activeTabGuest);
			scrollToTop();
		}
	};

	const TRANS_PREFIX = TranslatedPrefix(PREFIX, language);
	

	return (
		<div
			className="guestDetailsCheckout showGuestDetail"
			tabIndex='0'
			ref={ref}
		>
			<h3 className='textUppercase' style={{ textWrap: "wrap" }}>
				{t("room")} {index + 1}{": "}
				{item?.room_info?.room_name}
			</h3>
			<div className='guestDetailsWrap'>
				{index === 0 && <div className='dInlineBlock posRelative subTitles'>
					{t("guest_details")}
				</div>}
				<div className='checkoutTabDetails'>
					<div className='columnWrap'>
						{index === 0 ? <>
							<div className='column15 marginB25 mobileTwoColumn title'>
								<label className='inputFieldWrap'>
									<Select
										title='Prefix'
										dropdown={TRANS_PREFIX}
										rest={register(`guest.${index}.prefix`, {
											onChange: (e) => {
												setValue('prefix', alphabeticName(e.target.value));
											},
										})}
										defaultValue={"Prefix"}
									// error={errors?.guest?.[index]?.prefix?.message}
									/>
									{errors?.guest?.[index]?.prefix?.message &&
										<p className='errorMessage pL-1'>{errors?.guest?.[index]?.prefix?.message}</p>
									}
								</label>
							</div>
							<div className='column33 marginB25 mobileTwoColumn'>
								<label className='inputFieldWrap'>
									<Input
										title={t("first_name")}
										maxLength='20'
										rest={register(`guest.${index}.firstName`, {
											onChange: (e) => {
												setValue(
													`guest.${index}.firstName`,
													alphabeticName(e.target.value)
												);
												setValue('firstName', alphabeticName(e.target.value));
											},
										})}
									/>
									<span className='label' aria-lablledby={t("first_name")}>{t("first_name") + "*"}</span>
									{errors?.guest?.[index]?.firstName?.message &&
										<p className='errorMessage pL-1'>{errors?.guest?.[index]?.firstName?.message}</p>
									}
								</label>
							</div>
							<div className='column50 marginB25'>
								<label className='inputFieldWrap'>
									<Input
										title={t("last_name")}
										maxLength='20'
										rest={register(`guest.${index}.lastName`, {
											onChange: (e) => {
												setValue(
													`guest.${index}.lastName`,
													alphabeticName(e.target.value)
												);
												setValue('lastName', alphabeticName(e.target.value));
											},
										})}
									/>
									<span className='label' aria-lablledby={t("last_name")}>{t("last_name") + "*"}</span>
									{errors?.guest?.[index]?.lastName?.message &&
										<p className='errorMessage pL-1'>{errors?.guest?.[index]?.lastName?.message}</p>
									}
								</label>
							</div>
							<PhoneInput
								title={t("phone_number")}
								required
								maxLength='15'
								rest={register("phoneNumber", {
									onChange: (event) => {
										setValue(
											`phoneNumber`,
											event.target.value.replace(
												NUMBER_REGEX,
												""
											)
										);
									},
								})}
								codeRest={register("phoneCode")}
								phoneCodeError={errors.phoneCode?.message}
								errors={errors}
								bookingData={bookingData}
								watch={watch}
								setValue={setValue}
							/>
							<div className='column50 marginB25'>
								<label className='inputFieldWrap'>
									<Input
										title={t("email")}
										type='email'
										maxLength='50'
										rest={register("email", {
											onChange: (event) => {
												setValue(
													"email",
													replaceEmoji(event.target.value)
												);
											},
										})}
									/>
									<span className='label' aria-lablledby={t("email_address")}>{t("email_address") + "*"}</span>
									<p className="pL-1" style={{ fontSize: "12px" }}>{t("email_confirmation_helper_text")}</p>
									{errors.email?.message &&
										<p className='errorMessage pL-1'>{errors.email.message}</p>
									}
								</label>
							</div>
							<div className='column50 marginB25'>
								<label className='inputFieldWrap'>
									<Input
										title={t("iatano")}
										inputMode='numeric'
										maxLength='8'
										rest={register("iatano", {
											onChange: (event) => {

												let iataNo = replaceEmoji(
													event.target.value
												);
												if (iataNo.length > 8) {
													iataNo = iataNo.slice(0, 8);
												}
												setValue(
													`iatano`,
													iataNo.replace(NUMBER_REGEX, "")
												);
											},
										})}
									/>
									<span className="label">{t("iatano")}</span>
									{errors.iatano?.message &&
										<p className='errorMessage pL-1'>{errors.iatano.message}</p>
									}
								</label>
							</div>
							{fields?.length > 1 && <div className='column100 marginB25 customCheckBox'>
								<Checkbox
									text={t("stay_room_checkoutV2")}
									id={`stayInRoom`}
									isChecked={
										!watch("notStayRoom") && checkboxIndex === index
									}
									rest={register(`stayInRoom`, {
										onChange: (event) => {
											if (event.target.checked) {
												setCheckBoxIndex(index);
												setValue(
													`stayInRoom`,
													event.target.checked
												);
												setValue(
													"notStayRoom",
													watch("stayInRoom")
														? false
														: true
												);
												if (
													watch("stayInRoom")
												) {
													getValues("guest").forEach((guest, innerIndex) => {
														if (innerIndex !== 0) {
															setValue(
																`guest.${innerIndex}.prefix`,
																watch("prefix")
															);
															setValue(
																`guest.${innerIndex}.firstName`,
																watch("firstName")
															);
															setValue(
																`guest.${innerIndex}.lastName`,
																watch("lastName")
															);
															clearErrors(
																`guest.${innerIndex}.firstName`
															);
															clearErrors(
																`guest.${innerIndex}.lastName`
															);
															clearErrors(
																`guest.${innerIndex}.prefix`
															);
														}
													})
												}
											} else {
												setCheckBoxIndex();
												setValue("notStayRoom", true);
												getValues("guest").forEach((guest, innerIndex) => {
													if (innerIndex !== 0) {
														setValue(
															`guest.${innerIndex}.prefix`,
															""
														);
														setValue(
															`guest.${innerIndex}.firstName`,
															""
														);
														setValue(
															`guest.${innerIndex}.lastName`,
															""
														);
													}
												})
											}
										},
									})}
								/>
							</div>}
						</> : <>
							<div className='column33 marginB25 mobileTwoColumn'>
								<label className='inputFieldWrap'>
									<Select
										title='Title'
										dropdown={TRANS_PREFIX}
										rest={register(`guest.${index}.prefix`)}
										isDisabled={watch(`guest.${index}.stayInRoom`)}
									/>
									{errors?.guest?.[index]?.prefix?.message &&
										<p className='errorMessage pL-1'>{errors?.guest?.[index]?.prefix?.message}</p>
									}
								</label>
							</div>
							<div className='column33 marginB25 mobileTwoColumn'>
								<label className='inputFieldWrap'>
									<Input
										title={t("first_name")}
										maxLength='20'
										rest={register(`guest.${index}.firstName`, {
											onChange: (e) => {
												setValue(
													`guest.${index}.firstName`,
													alphabeticName(e.target.value)
												);
											},
										})}
									/>
									<span className='label' aria-lablledby={t("first_name")}>{t("first_name") + "*"}</span>
									{errors?.guest?.[index]?.firstName?.message &&
										<p className='errorMessage pL-1'>{errors?.guest?.[index]?.firstName.message}</p>
									}
								</label>
							</div>
							<div className='column33 marginB25'>
								<label className='inputFieldWrap'>
									<Input
										title={t("last_name") + index}
										maxLength='20'
										rest={register(`guest.${index}.lastName`, {
											onChange: (e) => {
												setValue(
													`guest.${index}.lastName`,
													alphabeticName(e.target.value)
												);
											},
										})}
									/>
									<span className='label' aria-lablledby={t("last_name") + index}>{t("last_name") + "*"}</span>
									{errors?.guest?.[index]?.lastName?.message &&
										<p className='errorMessage pL-1'>{errors?.guest?.[index]?.lastName?.message}</p>
									}
								</label>
							</div>
						</>}
					</div>
				</div>
			</div>
			{/* {errors?.guest?.[index] && (
				<p className='errorMessage'>{t("guest_details_required")}</p>
			)} */}
		</div>
	);
};
export default GuestInfo;
