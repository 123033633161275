import React, { useState } from "react"

const useAppContext = () => {
  const [authToken, setAuthToken] = useState("")
  const [configData, setConfigData] = useState("")
  const [yourStay, setYourStay] = useState(false)
  const [languageData, setLanguageData] = useState();
  const [currencyData, setCurrencyData] = useState()
  const [roomData, setRoomData] = useState([])
  const [offerData, setOfferData] = useState([])
  const [hotelData, setHotelData] = useState([])
  const [cartData, setCartData] = useState([])
  const [compareRooms, setCompareRooms] = useState(false)
  const [compareRoomsData, setCompareRoomsData] = useState([])
  const [numberOfRoom, setnumberOfRoom] = useState(1)
  const [enhancementChange, setEnhancementChange] = useState(false)
  const [confirmationData, setConfirmationData] = useState({})
  const [isBookingData, setIsBookingData] = useState(false)
  const [isErrorTriggered, setIsErrorTriggered] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(null)
  const [policyLoading, setPolicyLoading] = useState(false)
  const [codeAdded, setAddedCode] = useState(false)
  const [oldCode, setOldCode] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0);
  const [bookingToken, setBookingToken] = useState("");
  const [checkoutnoavailabilityerror, setcheckoutnoavailabilityerror] = useState(0);
  const [reservationToken, setReservationToken] = useState("");
  const [inquireData, setInquireData] = useState([]);
  const [roomListingPriceCheck, setRoomListingPriceCheck] = useState("");
  const [commaBookingToken, setCommaBookingToken] = useState("");
  const [offsetChange, setOffsetChange] = useState(false);
  const [closeUpMsg, setCloseUpMsg] = useState("");
  let [roomStepSelected,setRoomStepSelected]=useState(undefined);
  const [stepContext, setStepContext] = useState(false)

  return {
		authToken,
		configData,
		languageData,
		currencyData,
		roomData,
		offerData,
		cartData,
		compareRooms,
		compareRoomsData,
		yourStay,
		numberOfRoom,
		enhancementChange,
		hotelData,
		confirmationData,
		isBookingData,
		isErrorTriggered,
		overlay,
		selectedPhone,
		policyLoading,
		codeAdded,
		oldCode,
		activeSlide,
		checkoutnoavailabilityerror,
		bookingToken,
		reservationToken,
	    inquireData,
		roomListingPriceCheck,
		commaBookingToken,
		closeUpMsg,
		offsetChange,
		roomStepSelected,
		stepContext, 
		setStepContext,
		setRoomStepSelected,
		setAuthToken,
		setConfigData,
		setLanguageData,
		setCurrencyData,
		setRoomData,
		setOfferData,
		setCartData,
		setCompareRooms,
		setCompareRoomsData,
		setYourStay,
		setnumberOfRoom,
		setEnhancementChange,
		setHotelData,
		setConfirmationData,
		setIsBookingData,
		setIsErrorTriggered,
		setOverlay,
		setSelectedPhone,
		setPolicyLoading,
		setAddedCode,
		setOldCode,
		setActiveSlide,
		setcheckoutnoavailabilityerror,
		setBookingToken,
		setReservationToken,
		setInquireData,
		setRoomListingPriceCheck,
		setCommaBookingToken,
		setOffsetChange,
		setCloseUpMsg
  };
}

export default useAppContext
