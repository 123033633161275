import React, { useContext, useEffect } from "react";
import Header from "./Header";
import WithFooter from "./Footer/withFooter";
import AppContext from "../../../utils/appContext";
import { useMessageClosedUserGroup } from "../../../hooks/useMessageClosedUserGroup";
import MessageClosedUserGroup from "./Header/messageClosedUserGroup";

/**
 * Component for wrapping the layout with header, footer, and additional features.
 * 
 * @param {Object} props - The props object
 * @param {ReactNode} props.children - The children components to be wrapped
 * @param {Array} props.currencyList - The list of currencies
 * @param {string} props.authToken - The authorization token
 * @param {Object} props.siteConfig - The configuration data for the site
 */
const LayoutWrapper = ({ children, currencyList, authToken, siteConfig }) => {
  const context = useContext(AppContext);
  const { messageClosedUserGroup, isPopupVisible, setIsPopupVisible } = useMessageClosedUserGroup({ authToken, siteId: siteConfig?.site_id })

  // Set the authorization token in the context when it changes
  useEffect(() => {
    context.setAuthToken(authToken);
  }, [authToken]);

  // Set the site configuration data in the context when it changes
  useEffect(() => {
    context.setConfigData(siteConfig);
  }, [siteConfig]);

  // Load Google Tag Manager script when hotel data with mapping detail is available
  useEffect(() => {
    if (context.hotelData?.wihp_mapping_detail) {
      const inputString = context.hotelData?.wihp_mapping_detail;
      const conversionIdMatch = inputString.match(/"google_wihp_conversion_id";s:\d+:"([^"]+)"/);
      const conversionId = conversionIdMatch ? conversionIdMatch[1] : null;
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=AW-${conversionId}`;
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', `AW-${conversionId}`);
        gtag('config', 'HA-75', { 'conversion_cookie_prefix': '_ha' });
      };
    }
  }, [context.hotelData?.wihp_mapping_detail])

  // Render the layout components
  return (
    <>
      {messageClosedUserGroup && <MessageClosedUserGroup msg={messageClosedUserGroup} isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} />}
      <Header
        currencyList={currencyList}
        siteConfig={siteConfig}
        authToken={authToken}
      />
      {/* Check if the popup is visible and add or remove the 'alertMessageVisible' class to the body accordingly */}
      {isPopupVisible && messageClosedUserGroup ? document.body.classList.add('alertMessageVisible') : document.body.classList.remove('alertMessageVisible')}
      {children}
    </>
  );
};

export default WithFooter(LayoutWrapper);