import React, { useContext, useEffect, useState } from "react";
import BedIcon from "../../styles/images/bed-icon.png";
import UserIcon from "../../styles/images/user-icon.png";
import SizeIcon from "../../styles/images/size-icon.png";
import RoomView from "../../styles/images/room-view-icon.png";
import ConnectingRoomIcon from "../../styles/images/connecting-rooms.svg";
// import ReactHtmlParser from "react-html-parser";
import { KEYS } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import {
	capitalizeStr,
	currencyDisplay,
	roomDescriptionDisplay,
	isTaxInclusiveInCartSingleItem,
	setTaxInclusiveInSession,
	isInView,
} from "../../utils/utils";
import getSymbolFromCurrency from "currency-symbol-map";
import RateDetails from "./rateDetails";
import RoomImages from "./roomImages";
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/route";
import EnquireModal from "../common/Modal/enquireModal";
import AddtoCart from "./../common/Buttons/addtoCart";
import { useTranslation } from "react-i18next";
import AppContext from "../../utils/appContext";
import PolicyModal from "../common/Modal/policyModal";
import { decode } from "html-entities";
import moment from "moment";
import { SITE_ID_LIST_HASH } from "../../utils/utils";
import RoomDetailOverlay from "./roomDetailOverlay";



const RoomListDetails = ({
	item,
	siteConfig,
	hotelData,
	showAddToStay = false,
	viewBy
}) => {
	const [viewRates, setViewRates] = useState(false);
	const [openEnqiry, setOpenEnquiry] = useState(false);
	const [activeRoom, setActiveRoom] = useState(null);
	const [openPolicy, setOpenPolicy] = useState(false);
	const [availablerateinfo, setavailablerateinfo] = useState([]);
	const [open, setOpen] = useState(false);

	const { t } = useTranslation();
	const navigate = useNavigate();
	const context = useContext(AppContext);

	const handleClose = () => {
		setOpen(false)
	}


	const details = (keyHighlight) => {
		const descData = roomDescriptionDisplay(keyHighlight);
		let bedData = descData?.[0];
		const sleepData = descData?.[1];
		const roomview = descData?.[2];
		const sizeData = descData?.[3];
		const freetext = descData?.[4];

		let displayfeature = 1;

		if (bedData) {
			displayfeature++;
		}
		if (sleepData) {
			displayfeature++;
		}
		if (sizeData) {
			displayfeature++;
		}

		// Sanitize 'Room view' data
		let roomViewArr = roomview.split(/[:,]/); // break 'string' from ':' & ',' and convert it into array 
		roomViewArr = roomViewArr.filter((elm) => elm); // remove empty values from array
		let roomViewStr = '';   // Add 'room view' text as prefix

		roomViewArr?.map((element, index) => {
			element = element.trim(); // remove space from start & end of the string
			if (index > 1 && index <= roomViewArr.length) { // if element's index is not equal to first, second and last
				roomViewStr += ", ";
			}
			if (element) {
				roomViewStr += t(element);
			}
			if (index === 0) { // add ':' after first element to consider as Title
				roomViewStr += ": ";
			}
			return roomViewStr;
		});
		// Sanitize 'Room view' data

		return (
			<ul className='paddingB15'>
				{bedData && (
					<li className="keyHighlights">
						<img src={BedIcon} style={{ marginTop: "7px", marginBottom: "auto" }} alt={t("bed")} width='16' />
						<span>{bedData}</span>
					</li>
				)}
				{sleepData && (
					<li className="keyHighlights">
						<img src={UserIcon} style={{ marginTop: "7px", marginBottom: "auto" }} alt={t("users")} width='16' />
						<span>{sleepData}</span>
					</li>
				)}
				{roomview && (
					(
						<li className="keyHighlights">
							<img src={RoomView} style={{ marginTop: "7px", marginBottom: "auto" }} alt='Size' width='16' />
							<div>
								<span>{roomViewStr}</span>
								<div>{keyHighlight?.room_attr?.additional_text ? keyHighlight?.room_attr?.additional_text : ""}</div>
							</div>
						</li>
					)
				)}
				{sizeData && (
					<li className="keyHighlights">
						<img src={SizeIcon} style={{ marginTop: "7px", marginBottom: "auto" }} alt={t("size")} width='16' />
						<span>{sizeData}</span>
					</li>
				)}
				{freetext && displayfeature < 4 && (
					<li className="keyHighlights">
						<img
							style={{ marginTop: "7px", marginBottom: "auto" }}
							src={ConnectingRoomIcon}
							alt={t("connecting_room")}
							width='16'
						/>
						<span>{freetext}</span>
					</li>
				)}
			</ul>
		);
	};

	const goToViewRoom = () => {
		navigate(route.roomDetail, {
			state: {
				id: item?.group_room !== 1 ? item.room_id : activeRoom?.id,
			},
		});
	};

	const resultArr = !showAddToStay
		? Object.values(
			item?.price?.reduce((acc, curr) => {
				const roomGroup = curr.room_group;
				for (const key in roomGroup) {
					const room = roomGroup[key];
					const roomKey = JSON.stringify(room);
					if (!acc[roomKey]) {
						acc[roomKey] = { ...room, id: Number(key) };
					}
				}
				return acc;
			}, {})
		)
		: item?.price?.room_group &&
		  Object.entries(item?.price?.room_group).map(([key, value]) => {
				return {
					id: Number(key),
					...value,
				};
		  });

	const resultWithoutSort = resultArr?.length
		? resultArr.reduce((accumulator, obj) => {
			const found = accumulator.some(
				(item) => item.room_id === obj.room_id
			);
			if (!found) {
				accumulator.push(obj);
			}
			return accumulator;
		}, [])
		: [];

	const result = resultWithoutSort?.map((item) => {
		const dataArray = Object.values(item.rates);
		dataArray.sort((a, b) => a.min_price - b.min_price);
		const sortedData = {};
		dataArray.forEach((item) => {
			sortedData[item.rate_id] = item;
		});
		return {
			...item,
			rates: dataArray,
		};
	});
	let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
	let cartItems = SessionStorage.getJSON(KEYS.ROOM_DATA);
	let singleRoom = cartItems?.find((item) => item?.room == currentRoom);
	if (singleRoom === undefined) {
		currentRoom = currentRoom - 1;
		LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, currentRoom);
		singleRoom = cartItems?.find((item) => item?.room == currentRoom);
	}
	let occKey = "";
	occKey = singleRoom?.adults + "-" + singleRoom?.childrens?.length + "i0";
	let checkInDate = SessionStorage.get(KEYS.CHECK_IN_DATE)
	let checkoutDate = SessionStorage.get(KEYS.CHECKOUT_DATE)
	let checkIn = moment(checkInDate, "YYYY-MM-DD");
	let checkout = moment(checkoutDate, "YYYY-MM-DD");
	let totalNights = checkout.diff(checkIn, "days");

	let minimumSum = Number.MAX_VALUE;
	if (item?.group_room === 1) {
		if (!showAddToStay) {
			activeRoom?.rates?.forEach(item => {
				const datewiserates = item.datewiserates || {};
				const nestedValues = datewiserates[occKey] || {};
				const rateSum = Object.values(nestedValues).reduce((acc, val) => acc + val, 0);
				if (rateSum < minimumSum) {
					minimumSum = rateSum / totalNights;
				}
			});
		}
	} else {
		if (showAddToStay) {
			const datewiserates = item?.price?.datewiserates || {};
			const nestedValues = datewiserates[occKey] || {};
			const rateSum = Object.values(nestedValues).reduce((acc, val) => acc + val, 0);
			if (rateSum < minimumSum) {
				minimumSum = rateSum / totalNights;
			}
		} else {
			item?.price?.forEach(it => {
				const datewiserates = it.datewiserates || {};
				const nestedValues = datewiserates[occKey] || {};
				const rateSum = Object.values(nestedValues).reduce((acc, val) => acc + val, 0);
				if (rateSum < minimumSum) {
					minimumSum = rateSum / totalNights;
				}
			});
		}
	}

	let showroomdesc =
		item?.group_room === 1
			? activeRoom?.room_short_desc
			: item?.room_short_desc
				? item?.room_short_desc
				: "";

	if (showroomdesc != "" && showroomdesc != undefined) {
		showroomdesc = showroomdesc.replace(/(<([^>]+)>)/gi, "");
		showroomdesc = showroomdesc.replace(/(\r\n|\n|\r|\t)/gm, ""); // remove \r\n\t from string
		showroomdesc = decode(showroomdesc); // replacing entities to characters
		showroomdesc = showroomdesc.slice(0, 150); // define short description character length
	}

	useEffect(() => {
		setViewRates(false);
		if (
			context?.hotelData?.room_occupancy !== undefined &&
			context?.hotelData?.room_occupancy?.[occKey]
		) {
			setavailablerateinfo(context.hotelData.room_occupancy[occKey].rate);
		}
	}, [context.roomData, context.offerData, context.hotelData]);

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			setViewRates(!viewRates);
		}
	};

	useEffect(() => {
		let groupValue

		if (item?.group_room === 1) groupValue = Object.values(item?.price?.[0]?.room_group || item?.price?.room_group || {})?.map(value => value?.room_type_name)?.includes(context?.roomStepSelected?.room_info?.room_name);

		if (context?.roomStepSelected?.room_info?.room_name && ((context?.roomStepSelected?.room_info?.room_name === item?.room_name) || groupValue)) {
			setViewRates(false);
			setTimeout(() => {
				if (context?.roomStepSelected?.room_info?.room_id) {
					const roomListElement = document.getElementById(context?.roomData?.find(room => Object.values(room?.price?.[0]?.room_group?.[context?.roomStepSelected?.room_info?.room_id] || {})?.includes(context?.roomStepSelected?.room_info?.room_name))?.group_name || context?.roomStepSelected?.room_info?.room_name)
					roomListElement?.scrollIntoView({ behavior: "smooth" });
					setTimeout(() => {
						const cartElement = document.getElementById(`Room ${context?.roomStepSelected.room}`)
						cartElement?.scrollIntoView({ behavior: "smooth" });
					}, 1000);
				}
				if (viewBy === "rooms") setViewRates(true);
			}, 1000);
		} else {
			setViewRates(false);
		}
	}, [context.roomStepSelected])

	const showHideRates = (arg) => {
		setViewRates(arg === "open" ? true : false);
		var footer = document.getElementById("mainFooter");
		var mainCart = document.getElementById("mainCart");
		var mainContDiv = document.getElementsByClassName("mainContent");
		mainContDiv = mainContDiv[0];
		if (mainCart) {
			var visible = isInView(footer);
			if (visible) {
				mainCart.classList.add("stickySidebar");
				mainContDiv.classList.remove("stickySidebarFooter");
			}
		}
	};

	let roomGroupPrice = 0
	if (item.group_room === 1 && showAddToStay && activeRoom) {
		let occ = activeRoom?.datewiserates?.[occKey]
		roomGroupPrice = Object?.values(occ||{}).reduce((acc, val) => acc + val, 0) / totalNights;
	}

	useEffect(()=>{

		let currentRoomSelected

		if(item?.group_room===1){
			if(viewBy==="rooms"){
				const {default_room,price}=item	

				if(context?.stepContext&&context?.roomStepSelected?.room_info?.rate_id&&Object.keys(item.price[0].room_group).includes(context?.roomStepSelected?.room_info?.room_id.toString())){
					currentRoomSelected=result.find((x) => x.id == context?.roomStepSelected?.room_info?.room_id)

				}else if(JSON.parse(SessionStorage.get("roomData")).map((cartElement) => cartElement.room_info.room_id).filter((roomId) => roomId == default_room).length<price?.[0]?.room_group?.[default_room]?.roomcount){
					currentRoomSelected= result.find((x) => x.id == item.default_room)
				}else{
					
					currentRoomSelected=result?.sort((current,next)=>current.order_on-next.order_on)?.filter((it) => JSON.parse(SessionStorage.get("roomData")).map((cartElement) => cartElement.room_info.room_id).filter((roomId) => roomId == it?.room_id).length < it.roomcount)[0]
				}

			}else {
				const {default_room,price}=item
				if(context?.stepContext&&context?.roomStepSelected?.room_info?.rate_id&&Object.keys(item?.price?.room_group).includes(context?.roomStepSelected?.room_info?.room_id.toString())){
					currentRoomSelected=result.find((x) => x.id == context?.roomStepSelected?.room_info?.room_id)

				}else if(JSON.parse(SessionStorage.get("roomData")).map((cartElement) => cartElement.room_info.room_id).filter((roomId) => roomId == default_room).length<price?.room_group?.[default_room]?.roomcount){
					currentRoomSelected= result.find((x) => x.id == item.default_room)
					
				}else{
					currentRoomSelected=result?.sort((current,next)=>current.order_on-next.order_on)?.filter((it) => JSON.parse(SessionStorage.get("roomData")).map((cartElement) => cartElement.room_info.room_id).filter((roomId) => roomId == it?.room_id).length < it.roomcount)[0]

				}
			}

		}

		setActiveRoom({...currentRoomSelected})
		
	},[item,context.cartData,context?.roomStepSelected])

	return (
		<div className='roomsList' id={item.group_room === 1 ? item?.group_name : item?.room_name} style={{scrollMarginTop:/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "180px" : "125px"}}>
			<div className={`columnWrap singleRoomsList ${viewRates && "showRoomRates"}`}>
				<RoomImages
					roomImage={item?.group_room !== 1 ? item.room_images : activeRoom?.room_images}
					roomDetails={item?.group_room !== 1 ? item : activeRoom}
				/>
				<div className='column50'>
					<div className='roomListDetails'>
						<h2 className='customTitle' /*onClick={() => goToViewRoom()}*/ onClick={() => setOpen(true)} tabIndex='0'>
							{item.group_room === 1 ? item?.group_name : item?.room_name ? capitalizeStr(item.room_name) : ""}
						</h2>
						{item?.group_room === 1 && (
							<ul className='roomTypes listInline textUppercase'>
								{result?.sort((current,next)=>current.order_on-next.order_on)?.filter((it) => JSON.parse(SessionStorage.get("roomData")).map((cartElement) => cartElement.room_info.room_id).filter((roomId) => roomId == it?.room_id).length < it.roomcount)?.map((it) => (
									<li
										className={`${activeRoom?.id === it.id ? "roomTypesActive" : "roomTypesDisable"}`}
										tabIndex='0'
										key={it.id}
										onClick={() => {
											setActiveRoom(it)
										}}
									>
										{it.room_type_name}
									</li>
								))}
							</ul>
						)}

						{item?.group_room !== 1 &&
							((item?.min_price && !showAddToStay) || (item?.price?.occupancies && showAddToStay)) && (
								<span className='bookingFromPrice'>
									{siteConfig?.allow_average_price === 1 && t("from")}{" "}
									{LocalStorage.get(KEYS.SELECTED_CURRENCY) && getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
										? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY)) : LocalStorage.get(KEYS.SELECTED_CURRENCY)}
									{currencyDisplay(minimumSum)}{" "}{`(${SITE_ID_LIST_HASH.mbh?.includes(siteConfig?.site_id?.toString()) ? t("mbh_avg_price_helper_text") : t("average_per_night")})`}
								</span>
							)}
						<div className='roomShortDesc paddingTB10'>
							{showroomdesc && (<div dangerouslySetInnerHTML={{ __html: showroomdesc, }} />)}
						</div>
						{item?.group_room !== 1 && item.key_highlights && details(item.key_highlights)}
						{item?.group_room === 1 && activeRoom?.key_highlights && details(activeRoom.key_highlights)}
						{showAddToStay && (
							<div className='roomPricePloicy'>
								<a
									className='contentModal'
									href='javascript:void(0);'
									onClick={() => setOpenPolicy(true)}
								>
									{openPolicy && context.policyLoading ? t("policy_loading") : t("view_policies")}
								</a>
							</div>
						)}
						<PolicyModal
							openPolicy={openPolicy}
							setOpenPolicy={setOpenPolicy}
							rateId={item?.price?.rate_id}
							roomId={item.room_id}
							roomRateTaxInfo={item?.price?.rate_tax_info}
							policyCodes={item?.price?.policy_code_data}
						/>
						<div
							className='buttonsGroup alignItemsCenter flexWrap priceButtonsGroup'
							style={{ display: "flex" }}
						>
							<a
								className='customBtn customBtnTransparent'
								tabIndex='0'
								href='javascript:void(0);'
								onClick={() => {
									setOpen(true)
								}}
							>
								{t("view_room")}
							</a>
							{item.sale_type == 0 && (
								<a
									href='javascript:void(0);'
									className='customBtn customBtnDark'
									tabIndex='0'
									onClick={() => setOpenEnquiry(true)}
								>
									{t("enquire")}
								</a>
							)}
							{item?.min_price ? (
								<a
									className='customBtn customBtnDark viewRateBtn'
									tabIndex='0'
									onClick={() => showHideRates(viewRates ? "close" : "open")}
									onKeyDown={handleKeyDown}
								>
									{viewRates ? t("hide_rates") : t("view_rates")}
								</a>
							) : ""}
							{showAddToStay && (
								<AddtoCart
									room_id={item?.group_room === 1 ? activeRoom?.room_id : item.room_id}
									rate_id={item?.price?.rate_id}
									room_name={item?.group_room === 1 ? activeRoom?.room_name : item?.room_name}
									rate_name={item?.price?.rate_name}
									price={item?.group_room === 1 ? activeRoom?.datewiserates : item?.price?.datewiserates}
									is_tax_inclusive={setTaxInclusiveInSession(item?.price)}
									tax_status={isTaxInclusiveInCartSingleItem(item?.price)}
									section={"room_list"}
									rate_obj={item?.group_room === 1 ? activeRoom?.rates?.find(x => x?.rate_id === item?.price?.rate_id) : item?.price}
									tax_obj={item?.group_room === 1 ? activeRoom?.rates?.find(x => x?.rate_id === item?.price?.rate_id)?.tax : item?.price?.tax}
								/>
							)}
							{item?.group_room === 1 && activeRoom?.min_price && (
								<span className='bookingFromPrice' style={{ display: "grid" }}>
									<span>
										{t("from")}{" "}{LocalStorage.get(KEYS.SELECTED_CURRENCY) &&
											getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
											? getSymbolFromCurrency(LocalStorage.get(KEYS.SELECTED_CURRENCY))
											: LocalStorage.get(KEYS.SELECTED_CURRENCY)}
										{currencyDisplay(showAddToStay ? roomGroupPrice : minimumSum)}
									</span>
									<span>
										{`(${SITE_ID_LIST_HASH.mbh?.includes(siteConfig?.site_id?.toString()) ? t("mbh_avg_price_helper_text") : t("average_per_night")})`}
									</span>
								</span>
							)}
						</div>
					</div>
				</div>
				{viewBy==="rooms"&&viewRates && !item?.group_room ? item?.price.filter(({rate_id,roomcount})=>JSON.parse(window.sessionStorage.getItem("roomData"))?.filter(({room_info})=>room_info.room_id===item?.room_id&&room_info?.rate_id===rate_id)?.length<roomcount)?.map((rate) =>
					availablerateinfo.indexOf(rate.rate_id) != -1 ? (<RateDetails rate={rate} room={item} hotelData={hotelData} />) : "")
					: activeRoom?.rates && Object.values(activeRoom?.rates).map((x) =>
						availablerateinfo.indexOf(x.rate_id) != -1 ? (
							<RateDetails
								activeRoom={activeRoom}
								rate={x}
								room={item}
								hotelData={hotelData}
							/>
						) : ""
					)}
			</div>
			<EnquireModal
				openEnqiry={openEnqiry}
				setOpenEnquiry={setOpenEnquiry}
				hotelData={hotelData}
				roomDetails={item}
				siteConfig={siteConfig}
			/>

			{open && <RoomDetailOverlay open={open} handleClose={handleClose} item={item} activeRoom={activeRoom} details={details} siteConfig={siteConfig} showHideRates={showHideRates} viewBy={viewBy} showAddToStay={showAddToStay} roomGroupPrice={roomGroupPrice} minimumSum={minimumSum} setOpenEnquiry={setOpenEnquiry} />}

		</div>
	);
};

export default RoomListDetails;
